import React, { useState } from 'react'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import './style.scss'

function Qa(props) {
	const [faqA, setFaqA] = useState(false)

	function toggleFaq(e) {
		setFaqA(!faqA)
	}


	function toggleFaqIn(e) {
		anime({
			targets: e,
			translateY: ['-.5em',0],
			opacity: [0,1],
			scale: [.9, 1],
			duration: 400,
			delay: 0,
			easing: 'easeInOutSine'
		})
	}
	function toggleFaqOut(e) {
		anime({
			targets: e,
			translateY: [0,'-.5em'],
			opacity: [1,0],
			scale: [1, .9],
			duration: 400,
			delay: 0,
			easing: 'easeInOutSine'
		})
	}

  return (
		<div className="faq-qa">
			<div className={`faq-q ${props.i === 0 ? 'current' : faqA ? 'current' : ''}`} onClick={(e) => toggleFaq(e)} dangerouslySetInnerHTML={{__html: props.q}} onKeyDown={(e) => toggleFaq(e)} role="button" tabIndex={0}></div>
			<Transition
				in={props.i === 0 ? true : faqA ? true : false}
				timeout={400}
				appear={true}
				onEntering={toggleFaqIn}
				onExiting={toggleFaqOut}
				mountOnEnter
				unmountOnExit
			>
				<div className="faq-a fs-85" dangerouslySetInnerHTML={{__html: props.a}}></div>
			</Transition>
		</div>
  )
}

export default Qa