import React from 'react'
import './style.scss'
import logoSRC from './logo_compact.png'
//import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
//import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

function Footer(props) {
	//const image = props.image

  return (
		<footer id='master-footer' className='c5'>

			<div className="main-wrap fs-85">
				<div className="box fs-85">
					<div className="box no-wrap no-padding">
						<div className="box auto logo">
							<img src={logoSRC} alt="Alternative Logo" />
						</div>
						<div className="box auto no-padding">
							<div className="box center no-wrap wide no-padding">
								<div className="box auto center">
									<p><FontAwesomeIcon icon={faMapMarkerAlt} /> SWARM OFFICE</p>
								</div>
								<div className="box auto center">
									<h3>2308 NW 5<span className="square">th</span> Ave Miami, FL 33127</h3>
								</div>
							</div>
							<div className="box center no-wrap wide no-padding">
								<div className="box auto center">
									<p><FontAwesomeIcon icon={faMapMarkerAlt} /> SWARM WAREHOUSE</p>
								</div>
								<div className="box auto center">
									<h3>830 NW 22<span className="square">nd</span> ST Miami, FL 33127</h3>
								</div>
							</div>
							<div className="box no-wrap wide no-padding">
								<div className="box auto center">
									<h3><a href="tel:3054612700"><FontAwesomeIcon icon={faPhoneAlt} /> 305 461 2700</a></h3>
								</div>
								<div className="box auto center">
									<h3><a href="mailto:info@swarminc.com"><FontAwesomeIcon icon={faEnvelope} /> info@swarminc.com</a></h3>
								</div>
							</div>
						</div>
					</div>
					<div className="box wide no-padding">
						<div className="box center"><p className="uppercase copy">&copy; 2020 SWARM Event Agency All Rights Reserved <a target="_blank" rel="noreferrer" href="/privacy-policy">Privacy Policy</a><a target="_blank" rel="noreferrer" href="/terms">Terms</a><a target="_blank" rel="noreferrer" href="http://www.deepsleepstudio.com">Site &amp; Brand By</a></p></div>
					</div>
				</div>
			</div>

		</footer>
  )
}

export default Footer